<template>
    <layout :operation="'operations-center'"></layout>
</template>

<script>
import Layout from "@/views/front/jsf35/operations";
export default {
    name: "OperationsCenter",
	components: {
		Layout,
	},
};
</script>

<style lang="scss" scoped></style>
